@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;

  font-weight: 700 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.adm-tabs-tab-list{
  padding-top: 5em;
}
.selected{
  height: 60px;
  background-color: transparent !important;
  color: black !important;
}
.selectTypeBien,.btn-filter{
  width: 200px;
  margin:1em;
}
.selectTypeBien select{
  border-radius: 0px;
}
.p-tabview-nav{
  display: none !important;
}


.conteneur-residence{
  max-width: 400px;
  width: 100%;
  box-sizing: border-box;
  height: auto;
  background-color: rgb(255, 255, 255);
  border-radius: .6em;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.212);
}
.flex-2{
  flex: 2;
}
.image-residence{
  width: 100%;
  height: 400px;
  background-color: black;
}
/* .ecriteau-residence{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: auto;
} */
.all-conteneur{
  display: flex;
  align-content: center;
  justify-content: center;
  gap: 1em;
  flex-wrap: wrap;
}





.conteeur-image{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: .5em;
}

.conteeur-1{
  width: 400px;
  height: 400px;
  border: 1px solid black;
}
.conteeur-image{
  width: 400px;
  height: 300px;
  background-color: brown;
}











code {
  font-family: 'Montserrat', sans-serif !important;
}
.conteneur_image_ecriteau{
  background-color: rgb(255, 255, 255) !important;
  border-radius: .6em;
  box-shadow: 1px 1px 1px 3px rgba(0, 0, 0, 0.122);
}
img{
  border-radius: .6em .6em 0 0;
}
.imagePropriete{
  width: 80px !important;
  height: 80px !important;
  border-radius: 0 !important;
}
.see_prop{
  text-decoration: none;
  color: rgb(255, 255, 255);
  background: black;
  padding: .5em;
}

.connexion_back_home{
  background-color: rgba(250, 250, 250, 0.285);
  border-radius: 50%;
  padding: .6em;
  color:black !important;
  font-weight: bold !important;
}

.conteneur-header{
  background-color: white;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.055);
  position: fixed;
  width: 100%;
  z-index: 99;
}
/* .section-ville{
  padding-top: 5em;
} */
.section-ville div .ecriteau-ville{
  border: 1px solid black;
  border-radius: 15px;
  margin:0 .5em;
  cursor:pointer;
}
.delete-img{
  right: .5em !important;
  cursor: pointer;
}
.lien-call{
  text-decoration: none !important;
  color:rgb(255, 255, 255) !important;
}

.section-ville div .ecriteau-ville img{
  height: 50px;
  width: 60px;
  background-color: red;
}
.btn-dash{
  background-color: rgb(225, 225, 225) !important;
  color: white !important;
}
table{
  border: 2px solid black;
  width: 100%;
  text-align: center;
}
.detail_conteneur{
  position: fixed;
  width: 100%;
  background-color: rgb(252, 252, 252);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.152);
  z-index: 99;

}
.residence-conteneur-mobile{
  height: auto;
  width: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.169);
}
button{
  font-weight: bold !important;
}

.conteneur-ville{
  width: 100%;
  display: flex;
  align-items: center;
  overflow: auto;
  padding: 1em 1.5em;
}
input,select{
  width: 100%;
  padding: 1em !important;
  margin-top: .5em;
  border-radius: 14px;
  border: 1px solid gray;
}
.btn_survole_color{
  border:1px solid teal !important;
background-color:'transparent' !important;
}
.img_card_description{
  flex:2 2 300px;
}
.info_card_description{
  flex:1 1 300px;
}
.adm-tabs-tab-active {
  color: #023a89 !important;
}
.card{
  border: none !important;
}
.btn_survole_color:hover{
  background-color: rgb(0, 0, 0) !important;
  color: white;
}

.conteneur_image{
  width: auto;
}
:where(.css-dev-only-do-not-override-6j9yrn).ant-carousel .slick-dots li button{
  height:10px !important;
  background-color: rgb(255, 255, 255) !important;

}
.slick-dots{
  bottom: 0 !important;
  background-color: rgba(89, 89, 89, 0.568);
  padding: 1em !important;
}
.status{
  right: 0;
  top: .3em;
}
.conteneur_image_ecriteau{
  background-color: aliceblue;
  height: 450px;
  padding: 0 !important;
 box-shadow: 1px 1px 1px 3px rgba(0, 0, 0, 0.07);
}
.conteneur_img_detail{
  width: 100% ;
  height: 450px ;
  position: relative ;
  border-radius: 10px;
}
.content_style {
  margin: 0;
  height: 450px;
  width:100%;
  object-fit: cover;
  border-radius: 10px;
}
.bienvenue{
  padding-top:4em;
}
.css-6hp17o-MuiList-root-MuiMenu-list{
  padding-bottom: 0 !important;
}
.screem-search{
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.427);

}
.header-search{
  background-color: white;
  position: relative;
  top: 150px;
  width: 100%;
  padding:0 1em 10em 0;
  overflow: auto;
  height: 100%;
  border-radius: 10px 10px 0 0;
  z-index: 999;
}
.btn-cancel-search{
  right: 0;
  top:0;
  background-color: black;
  color: white;
  padding: .3em;
}
/* .p-datatable-responsive-scroll{
  padding: 0 !important;
}
.conteneur-search{
  padding: 6em 1em !important;
} */
.image-detail{
  height: 100vh;
  width: 100%;
  padding: 1em;
}
.ecriteau{
  position: fixed;
  width: 100%;
  z-index: 30;
  background-color: white;
  padding: .5em;
  box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.248);
  
}



img{
  object-fit: cover;
  width: 100%;
}
.mobile_foot_bar{
  /* position: fixed; */
  bottom: 0;
  width: 100%;
  z-index: 80;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.996);
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.223);
}
.conteneur_detail_section{
  margin-bottom: 7em;
  position: relative;
}
.gallerie-container-images{
  position: fixed;
  top:0;
  left:0;
  display: flex;
  justify-content: center;
  place-items: center;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.388);
  z-index: 99999;
}
.adm-slider-track,.adm-slider-fill{
  height: 10px !important;
}
.adm-slider-thumb {
width: 35px !important;
height: 35px !important;
line-height: 35px !important;
}
.container-white{
  width: 90%;
  height: 500px;
  overflow-y: auto;
  background-color: white;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.25);
}
.mobile_foot_bar button{
  border: none;
  color: white !important;
  width: auto !important;
}
.back_accueil{
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background-color: white;
  box-shadow: 1px 3px 3px 3px rgba(0, 0, 0, 0.157);
  border-radius: 100%;
  position: absolute;
  top: .8em;
  left:1em;
  z-index: 99;
}
.css-1pz7awu-MuiButtonBase-root-MuiIconButton-root:hover{
  background-color:transparent !important;
}
.MuiIconButton-root{
  margin-right: -2.5em !important;
  font-size: 1em !important;
  text-transform: capitalize !important;
}

@media screen and (min-width:500px) {
  .conteneur_img_detail{
    width: 100% ;
    height: 500px;
    position: relative ;
  }
  .content_style {
    margin: 0;
    height: 500px;
    width:100%;
    object-fit: cover;
  }
}
@media screen and (max-width:700px) {
  
  .desktop-version{
    display: none;
    }
}


@media screen and (min-width:700px) {
  
 
  .mobile-section{
    display: none;
  }
}




